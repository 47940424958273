import { HttpError, Services } from 'truemarket-modules/src/services'
import { UIError } from 'truemarket-modules/src/models/ui'
import { FormDataset, FormField } from '../Forms'
import { Util } from '../Util'
import ModalOptions from './Options/ModalOptions'
import ConfirmModalOptions from './Options/ConfirmModalOptions'

class ModalButton {
  Text?: string;
  Type?: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'subtle';
  Invert?: boolean;
  Loading?: boolean;
  Icon?: string;
  Padding?: string;
  Bold?: boolean;
  OnClick?: (modal: Modal) => boolean | Promise<boolean> | void;

  constructor (init: Partial<ModalButton>) {
    this.Text = init.Text ?? ''
    this.Type = init.Type ?? 'primary'
    this.Invert = init.Invert ?? false
    this.Loading = init.Loading ?? false
    this.Icon = init.Icon ?? ''
    this.Padding = init.Padding ?? '0.75em 1.2em'
    this.Bold = init.Bold ?? false
    this.OnClick = init.OnClick
  }
}

class Modal {
  Id: string = Util.GetId();
  private Loading = false
  Heading?: string;
  HtmlBody?: string;
  Buttons: ModalButton[];

  FormFields: FormField[];
  FormFieldValueGetter: (() => FormDataset) | null = null;

  Errors: UIError[] = [];

  constructor (init: Partial<Modal>) {
    this.Heading = init.Heading
    this.HtmlBody = init.HtmlBody
    this.Buttons = init.Buttons ?? []
    this.FormFields = init.FormFields ?? []
  }

  SetLoading (loading: boolean): void {
    this.Loading = loading

    Services.EventBus.Trigger('ui:modal:set_loading', this)
  }

  Show (): void {
    Services.EventBus.Trigger('ui:modal:show', this)
  }

  Hide (): void {
    Services.EventBus.Trigger('ui:modal:hide', this.Id)
  }

  GetFormValues (): Promise<FormDataset> {
    return new Promise((resolve, reject) => {
      if (this.FormFieldValueGetter === null) {
        reject(new Error('No FormFieldValueGetter'))
        return
      }

      const result = this.FormFieldValueGetter()

      resolve(result)
    })
  }

  SetFormErrorState (error: HttpError, autoError = true): void {
    this.SetLoading(false)

    console.log(error, autoError)
  }

  BuildModel<TModel> (): Promise<TModel> {
    return this.GetFormValues().then((data) => {
      return data.BuildModel<TModel>()
    })
  }

  static ShowPredefined<TOptions extends ModalOptions> (opts: TOptions): void {
    Services.EventBus.Trigger('ui:predefinedModal:show', opts)
  }

  static Confirm (opts?: Partial<ConfirmModalOptions>): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const modalOpts = new ConfirmModalOptions(opts)

      modalOpts.OnYes = () => resolve(true)

      this.ShowPredefined<ConfirmModalOptions>(modalOpts)
    })
  }
}

export { ModalButton, Modal }
