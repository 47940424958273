import ModalOptions from './ModalOptions'

class ConfirmModalOptions extends ModalOptions {
  Title = 'Please Confirm'
  Content = 'Please confirm you wish to proceed'
  Destructive?: boolean
  OnYes?: () => void
  OnNo?: () => void
  YesText = 'Yes'
  NoText = 'No'

  constructor (init?: Partial<ConfirmModalOptions>) {
    super('Confirm')

    if (init) Object.assign(this, init)
  }
}

export default ConfirmModalOptions
