import { GetReportModel } from 'truemarket-modules/src/models/api/reports'
import ModalOptions from './ModalOptions'

class ReportPickerModalOptions extends ModalOptions {
  Reports: GetReportModel[] = []

  Callback?: (report: GetReportModel) => void;
  NewCallback?: () => void;

  constructor (init?: Partial<ReportPickerModalOptions>) {
    super('ReportPicker')

    if (init) Object.assign(this, init)
  }
}

export default ReportPickerModalOptions
