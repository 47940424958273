












import { DataTableColDef, DataTableDef, DataTableRowDef } from '@/app_code/DataTable'
import { Modal } from '@/app_code/Modals/Modal'
import CreateReportFromTemplateModalOptions from '@/app_code/Modals/Options/CreateReportFromTemplateModalOptions'
import ReportPickerModalOptions from '@/app_code/Modals/Options/ReportPickerModalOptions'
import DataTable from '@/components/UI/Elements/DataTable/DataTable.vue'
import { ValuationModel } from 'truemarket-modules/src/models/api/'
import { GetReportModel, ReportType } from 'truemarket-modules/src/models/api/reports'
import { ValuationStatus } from 'truemarket-modules/src/models/api/valuations/ValuationStatus'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {
    DataTable
  }
})
export default class ValuationsIndex extends Vue {
  private valuations: ValuationModel[] = []

  private dtDef = new DataTableDef(new DataTableRowDef(row => row.Property.PropertySnapshotId, [
    new DataTableColDef('Status', row => row.FormattedStatus),
    new DataTableColDef('Type', row => row.Property.PropertyType.Label),
    new DataTableColDef('Address', row => row.Property.Address.FormattedAddress),
    new DataTableColDef('Name', row => `${row.Contact.FirstName} ${row.Contact.LastName}`),
    new DataTableColDef('Phone', row => row.Contact.Phone)
  ]))

  @Watch('$route.query.statuses')
  loadData () {
    let statuses: ValuationStatus[] = []

    if (this.$route.query.statuses) {
      statuses = (this.$route.query.statuses as string).split(',').map((qp: string) => parseInt(qp)) as ValuationStatus[]
    }

    Services.API.Valuations.GetValuations(statuses).then((list) => {
      this.valuations = list
    })
  }

  mounted () {
    this.loadData()
  }

  editItem (itemId: string) {
    const opts = new ReportPickerModalOptions({
      Reports: [],
      Callback: (report: GetReportModel) => {
        console.log(report)

        this.$router.push(`/dashboard/admin/reports/${report.ReportId}`)
      },
      NewCallback: () => {
        Modal.ShowPredefined<CreateReportFromTemplateModalOptions>(new CreateReportFromTemplateModalOptions({
          ReportType: ReportType.Valuation,
          TypeId: itemId,
          Callback: (report) => {
            Services.API.Valuations.GetReports(itemId).then((reports) => {
              opts.Reports = reports

              Services.API.Valuations.SetStatus(itemId, ValuationStatus.InProgress)
            })
          }
        }))
      }
    })

    Services.API.Valuations.GetReports(itemId).then((reports) => {
      console.log(reports)

      opts.Reports = reports
    })

    Modal.ShowPredefined<ReportPickerModalOptions>(opts)
  }
}
