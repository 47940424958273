import { GetReportModel, ReportType } from 'truemarket-modules/src/models/api/reports'
import ModalOptions from './ModalOptions'

class CreateReportFromTemplateModalOptions extends ModalOptions {
  ReportType?: ReportType;
  TypeId?: string;
  Callback?: (report: GetReportModel) => void;

  constructor (init?: Partial<CreateReportFromTemplateModalOptions>) {
    super('CreateReportFromTemplate')

    if (init) Object.assign(this, init)
  }
}

export default CreateReportFromTemplateModalOptions
